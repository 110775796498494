import { all, fork } from "redux-saga/effects"
import authSagas from "./login/saga"



export default function* rootSaga() {
  yield all([
    authSagas(),
  ])
}
