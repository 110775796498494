import { clsx } from "clsx"
import { twMerge } from "tailwind-merge"
import ls from 'localstorage-slim';
ls.config.storage = sessionStorage;

export const getCurrentUser = () => {
  const defaultUser = {
    email: null,
    first_name: null,
    id: null,
    last_name: null,
    onboarded: null,
    photo: null,
    role: null,
    token: null,
    username: null,
    gql_token: null,
    phone_number_verified: null,
    is_bvn_verified: null,
    is_kyc_submitted: null,
    is_nin_verified: null,
  };

  const user = ls.get('gogo_current_user', { decrypt: true });

  return user || defaultUser;
};


export const setCurrentUser = (user) => {
  try {
    if (user) {
      ls.set('gogo_current_user', user, {
        encrypt: true,
      });
    } else {
      ls.remove('gogo_current_user');
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error);
  }
};


/**
* The function `getRootRoute` takes a pathname as input and returns the root route of the pathname.
* @param pathname - The `pathname` parameter is a string representing the current URL path.
* @returns The function `getRootRoute` returns a string value.
*/
export const getRootRoute = (pathname) => {
  const paths = pathname.split(/\//g).filter((x) => x !== "");
  if (paths.length === 1 && paths[0] === "") {
    return "/";
  } else {
    return `/${paths[0]}/`;
  }
};

export function cn(...inputs) {
  return twMerge(clsx(inputs))
}

export const newArrayWithIds = (array, idProperty) => {
  if (!Array.isArray(array)) throw Error("Data is not an array");
  if (array.length < 1) return [];
  if (!array.some((item) => Object.prototype.hasOwnProperty.call(item, idProperty))) throw Error(`Input array must have property "${idProperty}"`);

  return array.map((item) => {
    return {
      ...item,
      id: item[idProperty],
    };
  });
};

export const isEmptyObject = (obj) => {
  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      return false; // If the object has any own property, it's not empty
    }
  }
  return true; // If no own properties are found, the object is empty
};

export const getEndDate = ({ date, months }) => {
  const newDate = new Date(date);
  newDate.setMonth(date.getMonth() + Number(months));

  return newDate;
};

export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { day: '2-digit', month: 'short', year: 'numeric' };
  const parts = new Intl.DateTimeFormat('en-US', options).formatToParts(date);
  const day = parts.find(part => part.type === 'day').value;
  const month = parts.find(part => part.type === 'month').value;
  const year = parts.find(part => part.type === 'year').value;
  return `${day}-${month}-${year}`;
};
