const initialState = {
  overlayLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'TOGGLE_LOADING':
      return { ...state, overlayLoading: action.payload };

    default:
      return initialState;
  }
};

export default reducer;
