import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux'
import { Toaster } from 'sonner';
import { configureStore } from './redux/store';
import { BrowserRouter } from 'react-router-dom';

const App = React.lazy(() => import(/* webpackChunkName: "App" */ './App'));

const app = (
  <React.StrictMode>
    <Provider store={configureStore()}>
      <Suspense fallback={<div className='flex text-center'>
        <div className=" loading loading-spinner text-warning " />
      </div>}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
        <Toaster position='top-right' richColors closeButton/>
      </Suspense>
    </Provider>
  </React.StrictMode>
);

ReactDOM.render(app, document.getElementById("root"));
